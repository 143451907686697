<!--
 * @Author: lbh
 * @Date: 2021-04-30 18:32:42
 * @LastEditors: lbh
 * @LastEditTime: 2023-08-18 16:35:35
 * @Description: file content
-->
<template>
  <!-- 手機模式下, 顯示 下載app -->
  <div
    class="download-app"
    v-if="type != '1000' && isMobile &&  isRicepon"
  >
    <img :src="getImgPath('fotlog.png')" />
    <div class="content">
      <span>{{$t('download.title')}}</span>
      <span>{{$t('download.label')}}</span>
    </div>
    <div class="down">
      <a href="http://m.ricepon.com/member-web/qrcode/qrcode.html">{{$t('download.href')}}</a>
    </div>
  </div>
</template>
<script>
import util from "@/js/utils/util";
export default {
  name: "self-download",
  props: {
    type: {
      default: () => {
        return '1000';
      }
    }
  },
  data () {
    return {
      isMobile: false,
      isRicepon: false
    }
  },
  mounted () {
    this.isMobile = util.isMobile();
    this.isRicepon = util.isRicepon();
  },
  methods: {
    getImgPath (t) {
      return util.getImgPath(t);
    },
  }
}
</script>
<style lang="less" scoped>
.download-app {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  img {
    height: 60px;
  }
  .content {
    display: flex;
    flex-direction: column;
    color: #000;
    margin-left: 10px;
  }
  .down {
    margin-left: auto;
    a {
      text-decoration: none;
      color: #980000;
    }
  }
}
</style>